import axiosInstance, {baseURL} from './request'
import {getToken, setToken} from '../utils/user'
import {AxiosResponse} from 'axios'

/**
 *
 * @param user
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getTokenApi = async (user) =>{
    try{
        const data = await axiosInstance.post('/tg_users/gen_token',{ tgInitData:JSON.stringify(user)})
        if(data.code===0){
            setToken(data.data.token)
        }
        return data
    }catch (error){
        return error
    }
}
/**
 *
 * @param isRankByPoint
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getTgUserApi = async (isRankByPoint=true)=>{
    try{
        const data = await axiosInstance.get('/telegramBot/getTgUser',{params:{isRankByPoint:isRankByPoint}})
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getMultitapStatusApi = async ()=>{
    try{
        const data = await axiosInstance.get('/telegramBot/getMultitapStatus')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const upgradeMultitapStatusApi = async ()=>{
    try{
        const data = await axiosInstance.post('/telegramBot/upgradeMultitap')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getUpperEnergyLimitStatusApi = async ()=>{
    try{
        const data = await axiosInstance.get('/telegramBot/getUpperEnergyLimitStatus')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const upgradeUpperEnergyLimitApi = async ()=>{
    try{
        const data = await axiosInstance.post('/telegramBot/upgradeUpperEnergyLimit')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const getDailyRewardStatusApi = async ()=>{
    try{
        const data = await axiosInstance.get('/telegramBot/getDailyRewardStatus')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const claimDailyRewardApi = async ()=>{
    try{
        const data = await axiosInstance.post('/telegramBot/claimDailyReward')
        return data
    }catch (error){
        return error
    }
}

/**
 *
 * @param coins
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const addClickPointsApi = async (coins)=>{
    try{
        const data = await axiosInstance.post('/telegramBot/addClickPoints?coins='+coins)
        return data
    }catch (error){
        return error
    }
}

export const fetchAddPoints = async (coins) =>{
   await fetch(baseURL+'/telegramBot/addClickPoints?coins='+coins, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':getToken()
        },
        keepalive: true,
    });
}

/**
 *
 * @returns {Promise<axios.AxiosResponse<any>|*>}
 */
export const addAdPointsApi = async () =>{
    try{
        const data = await axiosInstance.post('/telegramBot/addAdpoints')
        return data
    }catch (error){
        return error
    }
}
