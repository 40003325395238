import React, {ReactNode, Suspense, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom'
import './index.scss'
import {Toaster} from 'react-hot-toast'
import HSpinner from '../../components/spinner'
export const Main = (
    {
        children,
        links,
        hideBottomBarRoutes,
    }: {
        children: ReactNode;
        links: { label: string; path: string }[];
        hideBottomBarRoutes:string[]
    }
) =>{
    const { pathname } = useLocation();
    const shouldHideBottomBar = hideBottomBarRoutes.some(route => pathname.startsWith(route));
    return (
        <div id="page" className="main-layout">
            <div className={'mainChildrenLayout'} style={{marginBottom:shouldHideBottomBar?'0px':'60px'}}>
                <Suspense
                    fallback={
                        <div className={'main-loading-div'}>
                            {/*<HSpinner width={'32px'} height={'32px'} color={'#FEFEFE'}/>*/}
                        </div>
                    }
                >
                    {children}
                </Suspense>
                <Toaster position="top-center" />
            </div>
            {
                !shouldHideBottomBar&&<div className={'main-bottom-bar'}>
                    {links.map(({ label, path }) => (
                        <div key={path} >
                            <div className={pathname.startsWith(path) ? 'main-bottom-item active':'main-bottom-item'}>
                                <Link
                                    to={path}
                                >
                                    <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                                        <li className={`bottom-icon ${path.startsWith('/')?path.substring(1):path}`}/>
                                        <span className={'bottom-label'}>{label}</span>
                                    </div>
                                </Link>
                            </div>
                            <div style={{width:'17px'}}/>
                        </div>

                    ))}
                </div>
            }

        </div>
    )
}
