import { lazy } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import {Main} from '../pages/main'

const Home = lazy(()=>import('../pages/home/index'))
const Friends = lazy(()=>import('../pages/friends/index'))
const Tasks = lazy(()=>import('../pages/tasks/index'))
const Withdraw = lazy(()=>import('../pages/withdraw/index'))
const LuckyDraw = lazy(()=>import('../pages/luckyDraw/index'))
const WithDrawDetail = lazy(()=>import('../pages/withdrawDetail/index'))
const WithDrawWallet = lazy(()=>import('../pages/withdrawWallet/index'))

const links: { label: string; path: string }[] = [
  { label: 'Tap', path: '/home' },
  { label: 'Friends', path: '/friends' },
  { label: 'Tasks', path: '/tasks' },
  { label: 'Withdraw', path: '/withdraw' },
  // { label: 'Market', path: '/clusters' },
];

const routes: RouteObject[] = [
  { path: '/home', element: <Home /> },
  { path: '/friends', element: <Friends /> },
  { path: '/tasks', element: <Tasks /> },
  { path: '/withdraw', element: <Withdraw /> },
];

const secondRoutes: RouteObject[] = [
  { path: '/luckyDraw', element: <LuckyDraw />},
  { path: '/withDraw/withdraw', element: <WithDrawDetail />},
  { path: '/withDraw/wallet', element: <WithDrawWallet />},
];


export function AppRoutes(props:any) {
  const {index} = props
  return (
    <Main links={links} hideBottomBarRoutes={secondRoutes.map((item=>item.path))  as string[]}>
      {useRoutes([
        { index: true, element: <Navigate to={index===3?'/withdraw':'/home'} replace={true} />},
        // { path: '/home', element: <Home /> },
        ...routes,
        ...secondRoutes,
        { path: '*', element: <Navigate to={'/home'} replace={true} /> },
      ])}
    </Main>
  );
}
