import stores from '../stores'
import Cookies from "js-cookie";
import {toJS} from 'mobx'

export const setToken = (token:string) => {
    stores.common.setToken(token);
    Cookies.set("token", token);
    window?.localStorage?.setItem("token", token);
};

export const getToken = () => {
    const _token = Cookies.get("token") || localStorage?.getItem("token") || stores.common.token;
    return !_token || _token === 'null' ? '' : _token
};


export const setCommonUserInfo = (userInfo:any) => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    stores.common.setUserInfo(userInfo);
}

export const getCommonUserInfo = ():any=>{
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
        stores.common.setUserInfo(JSON.parse(storedUserInfo));
    }
    return stores.common.userInfo;
}
